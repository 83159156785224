const messages = {
    ' ': ' ',   // space is used as a placeholder for empty translations
    btn_new: "Lisa uus",
    btn_save: "Salvesta",
    btn_delete: "Kustuta",
    btn_print: "Prindi",
    btn_undo: "Tühista",
    btn_login: "Logi sisse",
    btn_sign_in_google: "Logi sisse Google kontoga",
    btn_cancel: 'Katkesta',
    btn_refresh: 'Värskenda',
    btn_remove: 'Eemalda',
    btn_create: 'Loo',
    btn_dismiss: 'Sulge',
    btn_back: 'Tagasi',
    btn_yes: 'Jah',
    btn_no: 'Ei',
    btn_add_user: 'Lisa kasutaja',
    btn_invite_new_user: 'Kutsu uus kasutaja',
    btn_copy: 'Ava koopia',
    btn_ok: 'OK',
    btn_download: 'Lae alla',
    btn_email: 'Saada e-postiga',
    btn_setup: 'Seadistused',
    btn_go_home: 'Avalehele',
    info: 'Info',

    app_title: "Profit",
    code: "Kood",
    name: "Nimi",
    memo: "Lisainfo",
    frontpage: "Esileht",
    companies: "Firmad",
    accounts: "Kontoplaan",
    banks: "Pangad",
    purchaseinvoices: "Ostuarved",
    currencies: "Valuutad",
    warehouses: "Laod",
    units: "Mõõtühikud",
    objects: "Objektid",
    articles: "Kaubad",
    reg_code: "Reg.kood",
    vat_code: "KM.kood",
    type_id: "Tüüp",
    logout: "Logi välja",
    nr: "Nr",
    date: "Kuupäev",
    document_date: "Dokumendi kuupäev",
    companyCode: "Firma kood",
    companyName: "Firma nimi",
    total: "Kokku",
    currency: "Valuuta",
    currencyRateLabel: "Kurss",
    amount_to_pay: "Maksmata",
    statusLabel: "Staatus",
    waybillNumbers: "Saatelehe numbrid",
    summary_rows: "Ridade kokkuvõte",
    summary_obj: "Objektide kokkuvõte",
    payments: "Tasumised",
    // grid_memo: "Lisainfo",
    created_datetime: "Loodud",
    creator: "Looja",
    modified_datetime: "Muudetud",
    modifier: "Muutja",
    attsCnt: "Manuseid",
    edit_sales_title: "Müük",
    edit_address_title: "Aadress",
    edit_contacts_title: "Kontaktandmed",
    edit_primary_title: "Põhiandmed",
    edit_setup_title: "Seaded",
    setup_general_title: "Üldseaded",
    grid_companycontacts: "Kontaktid",
    grid_bank_accounts: "Pangakontod",
    grid_salesprices: 'Müügihinnad',
    grid_purchaseprices: 'Ostuhinnad',

    grid_purchaseinvoice_rows: 'Artiklid',

    company_id: 'Firma',
    supplierCode: 'Tarnija kood',
    purchasePrice: 'Ostuhind',
    pricelist_id: 'Hinnakiri',
    customer_account_id: "Kliendi konto",
    supplier_account_id: "Tarnija konto",
    customer: "Klient",
    supplier: "Tarnija",
    edit_attributes_title: "Lisainfo",
    phone: "Telefon",
    email: "E-post",
    fax: "Faks",
    contact_person: "Kontaktisik",
    legal_address_street: "Tänav",
    legal_address_city: "Linn",
    legal_address_country_id: "Riik",
    legal_address_postal_code: "Indeks",
    postal_address_street: "Tänav",
    postal_address_city: "Linn",
    postal_address_country_id: "Riik",
    postal_address_postal_code: "Indeks",
    legal_address_title: "Juriidiline aadress",
    postal_address_title: "Postiaadress",
    countries: "Riigid",
    prefix: "Prefiks",

    password: 'Salasõna',
    eu_code: "EU kood",
    account_group_id: "Rühm",

    "account.group.activa": "Aktiva",
    "account.group.passiva": "Passiva",
    "account.group.income": "Tulu",
    "account.group.expense": "Kulu",

    "account.type.detail": "Detailne",
    "account.type.summary": "Üldine",
    "login.company": "Firma",
    salesinvoices: "Müügiarved",
    
    buyer_id: "Ostja",
    due_period: "Maksetähtaeg",
    due_date_alt: "Makse tähtaeg",
    reference_number: 'Viitenumber',
    wh_id: 'Ladu',
    interest: "Viivise %",
    currency_Id: "Valuuta",
    rate: 'Kurss',
    drate: 'Kurss',
    nrpefix: 'Prefiks',
    nrsuffix: 'Sufiks',
    sum: 'Summa',
    summaryHeader: 'Kokkuvõte',
    article_id: 'Kaup',
    article_name: 'Kauba nimetus',
    quantity: 'Kogus',
    price: 'Hind',
    rounding: 'Ümardamine',
    nrprefix: 'Prefiks',        
    IBAN: 'IBAN',
    alert_document_loading: 'Laen dokumenti',
    alert_document_failed: 'Viga',
    alert_document_saved: 'Dokument salvestatud',
    alert_document_saving: 'Salvestan dokumenti',
    unit_id: 'Ühik',
    vats: 'Käibemaksumäärad',
    vat_id: 'KM%',
    vat_total: 'KM',
    total_vat: 'Käibemaks',
    correction: 'Parandus',
    vat_return: 'Tagastatav KM',
    rowTotal: 'Rida kokku',
    obj_id: 'Objekt',
    msg_delete_selected_rows: 'Kas kustutada valitud ridu?',
    question: 'Küsimus',
    msg_delete_document: 'Kas kustutada dokument?',
    'Field code should be set when adding new entry': 'Kood peab olema määratud uue kirje lisamisel',
    'Not unique': 'Ei ole unikaalne',
    'Validation errors': 'Ei saa salvestada, dokument on puudulik',
    'Required': 'Kohustuslik',
    'Currency rate must not be 0': 'Valuuta kurss ei tohi olla 0',
    'VAT applied': 'KM rakendatud',
    totals_vat_applied_to: 'KM rakendatud {vat_percent}%',
    totals_vat_total: 'KM {vat_percent}% kokku',
    'total without vat': 'Kokku ilma KM',
    'total with vat': 'Kokku KM-ga',
    bank_id: 'Pank',
    'contact': 'Kontakt',
    'payment_period': 'Maksetähtaeg',
    'unit': 'Ühik',
    'taxed with': 'Maksustatud',
    'bank.requisits': 'Pangarekvisiidid',
    'reg.code': 'Reg.kood',
    'vat.code': 'KMKR kood',
    'payer': 'Maksja',
    invoice_no: 'Arve nr',
    'Failed to fetch': 'Andmete laadimine serverist ebaõnnestus',
    'buyer': 'Ostja',
    unsaved_changes_prompt: 'Kas lahkuda ilma salvestamata?',
    register: 'Liitu tasuta',
    'my enterprises': 'Minu ettevõtted',
    'new enterprise': 'Uus ettevõte',
    'storage dashboard': 'Hoiustatud andmed',
    debug: 'Debug',
    discount: '-%',
    'company data': 'Firma andmed',
    company_data_tooltip: 'Vaata/muuda firma andmeid',
    'Not found, add': 'Ei leitud, lisa',
    'companyExtras': 'Firma lisainfo',
    user_menu: 'Konto seaded',
    'settings': 'Seaded',
    refresh: 'Värskenda',
    'billing data': 'Arveldusandmed',
    'goto enterprise': 'Ava ettevõte',
    'invite user': 'Kutsu kasutaja',
    'enterprise name': 'Ettevõtte nimi',
    'enterprise type': 'Tüüp',
    'default estonian oy': 'Vaikimisi Eesti OÜ',
    'empty database': 'Tühi andmebaas',
    'database creation error': 'Andmebaasi loomine ebaõnnestus',
    'unknown error': 'Tundmatu viga',
    'database creation error message': 'Andsime teada tehnilise toe meeskonnale. Palun proovi hiljem uuesti.',
    'database creation in progress': 'Andmebaasi loomine',
    status: 'Staatus',
    waiting: 'ootel',
    creating: 'loomine',
    'tabase successfully created': 'Andmebaas edukalt loodud',
    type_ext_id: 'Tüüp',
    'article.typeExt.expense': 'Kuluartikkel',
    'article.typeExt.product': 'Laoartikkel',
    financial_block_title: 'Finants',
    sales_block_title: 'Müük',
    barcode: 'Ribakood',
    pricelists: 'Hinnakirjad',
    articlegroups: 'Kaubagrupid',
    account_id: 'Konto',
    purchase_account_id: 'Ostukonto',
    purchase_vat_id: 'Ostu KM määr',
    parent_id: 'Ülemgrupp',
    articlegroup_id: 'Kaubagrupp',
    'show sidebar' : 'Näita küljepaneeli',
    'hide sidebar' : 'Peida küljepaneel',

    salesinvoices_due_report: 'Maksmata arvete aruanne',
    sales_report_title: 'Müükide aruanne {period_start} - {period_end}',
    sales_report: 'Müükide aruanne',
    period_start: 'Perioodi algus',
    period_end: 'Perioodi lõpp',
    payer_id: 'Maksja',
    due_date: 'Makse kuupäev',
    total_with_vat: 'Summa KM-ga',
    invoice_type_general: 'Tavaarve',
    invoice_type_cash: 'Sularahaarve',
    invoice_type_card: 'Kaardimaksearve',
    articlegroup: 'Kaubagrupp',
    types: 'Tüübid',
    sort_order: 'Järjestus',

    'doc_date, buyer_name, doc_nr': 'Kuupäev, ostja, nr',
    'buyer_name, doc_date, doc_nr': 'Nr, kuupäev, ostja',
    'doc_date, doc_nr, buyer_name': 'Kuupäev, nr, ostja',

    setup_additional_title: 'Lisaseaded',

    'FIRMA.NAME': 'Firma nimi',
    'FIRMA.KOOD': 'Registrikood',
    'FIRMA.KKNR': 'KM kohuslase nr',
    'FIRMA.AADRESS': 'Aadress',
    'FIRMA.TELEFON': 'Telefon',
    'FIRMA.EMAIL': 'E-post',
    'FIRMA.VALUUTA': 'Baasvaluuta',
    'FIRMA.VALUUTA2': 'Teine baasvaluuta',
    'DEF.FIRMAD.HINNAKIRIID': 'Baashinnakiri',
    'DEF.FIRMAD.MAKSETAHTAEG': 'Vaikimisi maksetähtaeg',
    'DEF.FIRMAD.HANKIJAKONTOID': 'Tarnijakonto',
    'DEF.FIRMAD.KLIENDIKONTOID': 'Kliendikonto',
    'DEF.FIRMAD.KMLIIKID': 'KM liik',
    'DEF.FIRMAD.KMDINF': 'KMD inf',

    'EMAIL.SMTP.SERVER': 'Meiliserver (SMTP)',
    'EMAIL.SMTP.PORT': 'Meiliserveri port',
    'EMAIL.SMTP.UID': 'Meiliserveri kasutaja',
    'EMAIL.SMTP.PWD': 'Meiliserveri salasõna',
    'EMAIL.SMTP.TLS': 'Turvalisuse seaded',
    'EMAIL.USESMTP': 'Kasuta SMTP',
    'EMAIL.SMTP.BCC': 'Saada alati pimekoopia (BCC)',
    'EMAIL.SMTP.SIGNATURE': 'E-kirja allkiri',
    'EMAIL.SUBJECT.DEFAULT': 'Vaikimisi e-kirja teema',

    'FIRMA.RIIK': 'Riik',
    article: 'Kaup',
    object: 'Objekt',
    vat: 'KM määr',
    article_group: 'Kaubagrupp',
    'currency selector': 'Valuuta valik',
    'interest editor': 'Intressi muutmine',
    'unit visible': 'Ühik nähtav',
    'unit editable': 'Ühik muudetav',
    'vat visible': 'KM nähtav',
    'vat editable': 'KM muudetav',
    'discount visible': 'Allahindluse veerg',
    'total w/o vat visible': 'Summa ilma KM-ta',
    'total vat visible': 'KM summa',
    'total with vat visible': 'Summa KM-ga',
    'show revenues in totals': 'Kuvada käived kokkuvõttes',
    obj_visible: 'Objekti veerg',
    invoice_nr_visible: 'Arve nr',
    due_date_visible: 'Maksetähtaeg',
    interest_visible: 'Viivise veerg',
    invoice_nr: 'Arve nr',

    colorscheme_light: 'Hele värvigamma',
    colorscheme_dark: 'Tume värvigamma',
    colorscheme_system: 'Süsteemi värvigamma',
    density_default: 'Vaikimisi tihedus', 
    density_comfortable: 'Mugav tihedus', 
    density_compact: 'Kompaktne tihedus',

    msg_error_loading_links: 'Seoste laadimine ebaõnnestus',

    doc_date: 'Kuupäev',
    buyer_name: 'Ostja',
    payer_name: 'Maksja',
    currency_code: 'Valuuta',
    currency_rate: 'Kurss',
    waybills: 'Saatelehed',
    to_pay: 'Maksmata',
    summaryrows: 'Ridade info',
    locked_icon: '🔒',
    attachment_icon: '📎',
    label_icon: '🏷️',
    emailed_status_icon: '📧',
    sender_login: 'Koostaja',
    grid_memo: 'Lisaväli',
    d_created: 'Loodud',
    d_modified: 'Muudetud',
    mdatts: 'Manuseid',
    labels: 'Sildid',

    setup_users_title: 'Kasutajad',
    setup_companies_title: 'Firmad',
    setup_company_title: 'Ettevõte seadistused',
    setup_printforms_title: 'Väljatrükivormid',
    setup_dangerous_title: 'Ohtlikud seadistused',
    setup_dangerous_warning: 'Järgmised seadistused on potentsiaalselt ohtlikud. Veenduge, et olete kindel, et soovite nendega jätkata.',
    setup_tech_title: 'Tehnilised seadistused',
    setup_users_no_database: 'Andmebaas puudub',
    title_add_new_user: 'Lisa uus kasutaja',
    select_new_user_name: 'Vali uue kasutaja nimi',
    tooltip_invite_new_user: 'Ei leia kasutajat? Kutsu uus kasutaja e-posti teel',

    email_invite_new_user_subject: 'Kutsun Sind kasutama Profiti majandustarkvara',
    email_invite_new_user_body: 'TODO siia tuleb meili sisu',
    msg_remove_user: 'Kas oled kindel, et soovid kasutaja kustutada?',
    msg_hello: 'Tere {name}',
    msg_no_name: 'Palun sisesta nimi, millega teised kasutajad Sind näevad. \nIlma nimeta ei saa Sind kutsuda ega leida.',
    lbl_enter_your_user_name: 'Sisesta oma nimi',
    msg_user_name_default: 'Kasutajanimi peab olema unikaalne, koosnema vähemalt 3 tähemärgist ja võib sisaldada ainult tähti, numbreid, punkte ja allkriipse.',
    msg_user_name_too_short: 'Kasutajanimi peab olema vähemalt 3 tähemärki pikk.',
    msg_user_name_too_long: 'Kasutajanimi ei tohi olla pikem kui 20 tähemärki.',
    msg_uniqueness_checked_on_save: 'Unikaalsust kontrollitakse salvestamisel.',
    msg_not_shared: 'Ettevõtte andmebaas ei ole kellegiga jagatud.',
    btn_edit_name: 'Muuda nime',
    btn_cancel_edit_name: 'Katkesta nime muutmine',

    db_status_ready: 'korras',
    db_status_waiting: 'loomise ootel',
    db_status_creating: 'luuakse',
    db_status_error: 'viga',
    db_status_updating: 'uuendatakse',

    msg_loading_databases_list: 'Laetakse andmebaaside andmeid',
    msg_no_databases: 'Ettevõtteid ei leitud, loo uus või oota kuni sinuga keegi jagab oma ettevõtte andmeid.',
    msg_cant_create_no_name: 'Ei saa luua uusi andmebaase, sest kasutajanimi puudub.',
    btn_lock: 'Kinnita',
    btn_unlock: 'Kinnitus maha',
    btn_filter: 'Filter',
    filter: 'Filter',

    doc_date_from: 'Perioodi algus',
    doc_date_until: 'Perioodi lõpp',
    btn_close: 'Sulge',
    total_from: 'Summa alates',
    total_until: 'Summa kuni',
    object_id: 'Objekt',
    currency_id: 'Valuuta',
    filter_active: 'Filter aktiivne',
    selected: 'rida valitud',
    payer_selector: 'Maksja valik',
    btn_register: 'Loo konto',
    version: 'Versioon {version}',
    db_status_archiving: 'Arhiveerimine',
    msg_archive_db: 'Kas oled kindel, et soovid andmebaasi kustutada?',
    btn_delete_db: 'Kustuta andmebaas',
    msg_database_archiving_long: 'Andmebaas on määratud kustutamisele.',
    title_database_archiving: 'Andmebaas kustutamisel',
    menu_archived_visible: 'Näidata kustutatud andmebaasid',
    menu_archiving_visible: 'Näidata kustutamisel olevad andmebaasid',
    title_database_archived: 'Kustutatud andmebaas',
    msg_database_archived_long: 'Andmebaas on kustutatud.',
    msg_setup_users_shared_database: 'Te ei ole selle andmebaasi omanik',
    msg_report_error: 'Viga aruande loomise ajal:{br}{error}',

    shorthelp_salesinvoices_nr: 'Müügiarve number.{br}Automaatselt genereeritud number, võib muuta',
    shorthelp_salesinvoices_salesinvoicerows_vat_id: 'Käibemaksu määr.{br}Käibemaksu määr, mida kasutatakse arvel',
    shorthelp_salesinvoices_memo: 'Lisainfo müügiarve kohta.{br}Ei kuvata arvel',
    shorthelp_salesinvoices_salesinvoicerows_discount: 'Allahindlus.{br}Rea allahindluse protsent',
    shorthelp_salesinvoices_salesinvoicerows_price: 'Hind.{br}Kauba hind ilma käibemaksuta, programm võib pakkuda müügihinda vastavalt ostja hinnakirjale',
    shorthelp_salesinvoices_salesinvoicerows_unit_id: 'Ühik.{br}Kauba ühik',
    shorthelp_salesinvoices_salesinvoicerows_quantity: 'Kogus.{br}Kauba kogus, vaikimisi 1',
    shorthelp_salesinvoices_salesinvoicerows_article_name: 'Kauba nimetus.{br}Kauba nimetus, vaikimisi tuleb kauba kaardilt.{br}Nime võib muuta',
    shorthelp_salesinvoices_salesinvoicerows_article_id: 'Kaup.{br}Kauba valik',
    shorthelp_row__sel: 'Vali rida.{br}Valitud ridu saab kustutada',
    shorthelp_salesinvoices_due_date: 'Makse kuupäev.{br}Sõltub arve kuupäevast ja maksetähtajast.{br}Muutmiseks muutke maksetähtaega',
    shorthelp_salesinvoices_due_period: 'Maksetähtaeg.{br}Maksetähtaeg arve väljastamise kuupäevast päevades.{br}Vaikimisi väärtus tuleb ostja andmetest',
    shorthelp_salesinvoices_date: 'Arve kuupäev.{br}Arve väljastamise kuupäev',
    shorthelp_salesinvoices_buyer_id: 'Ostja valik.{br}Valikusse tulevad aktiivsed kliendid firmade nimekirjast.{br}Kui ostja puudub, saab selle lisada.{br}Selleks sisestage ostja nimi ja vajutage "Lisa ..." rippmenüüs{br}"..." nupu abil saate vaadata või muuta ostja andmeid.',

    shorthelp_salesinvoices_filter_doc_date_from: 'Filtreeritud perioodi algus.{br}Kuvatakse arveid, mille kuupäev on suurem või võrdne',
    shorthelp_salesinvoices_filter_doc_date_until: 'Filtreeritud perioodi lõpp.{br}Kuvatakse arveid, mille kuupäev on väiksem või võrdne',
    shorthelp_salesinvoices_filter_total_from: 'Summa alates.{br}Kuvatakse arveid, mille summa on suurem või võrdne',
    shorthelp_salesinvoices_filter_total_until: 'Summa kuni.{br}Kuvatakse arveid, mille summa on väiksem või võrdne',
    shorthelp_salesinvoices_filter_article_id: 'Kauba filter.{br}Kuvatakse arveid, millel on valitud kaup',
    shorthelp_salesinvoices_filter_object_id: 'Objekti filter.{br}Kuvatakse arveid, millel on valitud objekt',
    shorthelp_salesinvoices_filter_buyer_id: 'Ostja filter.{br}Kuvatakse valitud ostja arveid',
    shorthelp_salesinvoices_filter_payer_id: 'Maksja filter.{br}Kuvatakse valitud maksja arveid',
    shorthelp_salesinvoices_filter_currency_id: 'Valuuta filter.{br}Kuvatakse arveid valitud valuutas',
    shorthelp_salesinvoices_filter_active: 'Filter toimib vaid siis, kui see linnuke on sisse lülitatud',
    shorthelp_sales_report_period_start: 'Aruande perioodi algus',
    shorthelp_sales_report_period_end: 'Aruande perioodi lõpp',
    shorthelp_sales_report_buyer_id: 'Kui valitud, jäävad aruandesse ainult valitud ostja arved',
    shorthelp_sales_report_payer_id: 'Kui valitud, jäävad aruandesse ainult valitud maksja arved',
    shorthelp_sales_report_supplier_id: 'Kui valitud, jäävad aruandesse ainult valitud tarnija kaupadega arved',
    shorthelp_sales_report_article_id: 'Kui valitud, jäävad aruandesse ainult valitud kaubaga arved',
    shorthelp_sales_report_object_id: 'Kui valitud, jäävad aruandesse ainult valitud objektiga arved',
    shorthelp_sales_report_vat_id: 'Kui valitud, jäävad aruandesse ainult valitud käibemaksuga arved',
    shorthelp_sales_report_grp_id: 'Kui valitud, jäävad aruandesse ainult valitud kaubagruppi kuuluvaid kaupu sisaldavad arved',
    shorthelp_sales_report_type_general: 'Kas näidata ülekandearveid{br}Kui kõik arve tüübi linnukesed on eemaldatud, kuvatakse kõik arved',
    shorthelp_sales_report_type_cash: 'Kas näidata sularaha arveid{br}Kui kõik arve tüübi linnukesed on eemaldatud, kuvatakse kõik arved',
    shorthelp_sales_report_type_card: 'Kas näidata kaardimakse arveid{br}Kui kõik arve tüübi linnukesed on eemaldatud, kuvatakse kõik arved',
    shorthelp_sales_report_sort_order: 'Arvete järjestuse valik',

    shorthelp_companies_name: 'Firma nimi',
    shorthelp_companies_code: 'Firma kood{br}Unikaalne kood, mida kasutatakse programmis sisemisel otstarbel nt firma valikutes.',
    shorthelp_companies_reg_code: 'Registrikood'
        + '{br}Täidetakse automaatselt, kui firma info tuleb äriregistrist',
    shorthelp_companies_vat_code: 'Käibemaksukohuslase number'
        + '{br}Täidetakse automaatselt, kui firma info tuleb äriregistrist',
    shorthelp_companies_supplier: 'Kas on tarnija{br}Kui linnuke on sisse lülitatud, siis on tegemist tarnijaga{br}Tarnijad tulevad firma valikusse ostuarves, ostutellimuses jm',
    shorthelp_companies_customer: 'Kas on klient{br}Kui linnuke on sisse lülitatud, siis on tegemist kliendiga{br}Kliendid tulevad firma valikusse müügiarves, müügitellimuses jm',

    shorthelp_companies_payment_period: 'Maksetähtaeg{br}Maksetähtaeg arve väljastamise kuupäevast päevades.{br}Tuleb vaikimisi arvele.',
    shorthelp_companies_interest: 'Viivise protsent{br}Tuleb müügiarvele',
    shorthelp_companies_currency_id: 'Eelistatud valuuta, milles antud partneriga arveldatakse{br}Tuleb vaikimisi müügiarvele',

    shorthelp_companies_legal_address_street: 'Juriidiline aadress: tänav, maja, korter'
        + '{br}n Kivi 21-1.'
        + '{br}Täidetakse automaatselt, kui firma info tuleb äriregistrist',
    shorthelp_companies_legal_address_city: 'Juriidiline aadress: linn'
        + '{br}n Tartu linn, Tartu linn, Tartu Maakond'
        + '{br}Täidetakse automaatselt, kui firma info tuleb äriregistrist',
    shorthelp_companies_legal_address_postal_code: 'Juriidiline aadress: postiindeks{br}n 12345'
        + '{br}Täidetakse automaatselt, kui firma info tuleb äriregistrist',
    shorthelp_companies_legal_address_country_id: 'Juriidiline aadress: riik{br}n Eesti',

    shorthelp_companies_postal_address_street: 'Postiaadress: tänav, maja, korter{br}n Kivi 21-1',
    shorthelp_companies_postal_address_city: 'Postiaadress: linn{br}n Tartu linn, Tartu linn, Tartu Maakond',
    shorthelp_companies_postal_address_postal_code: 'Postiaadress: postiindeks{br}n 12345',
    shorthelp_companies_postal_address_country_id: 'Postiaadress: riik{br}n Eesti',
    shorthelp_companies_phone: 'Telefoninumber{br}n +372 1234567',
    shorthelp_companies_email: 'E-posti aadress{br}n info@intellisoft.ee',
    shorthelp_companies_contact_person: 'Peamise kontaktisiku andmed{br}vt allpool kontaktide nimekirja',
    shorthelp_companies_fax: 'Faksinumber{br}n +372 1234567',
    shorthelp_companies_customer_account_id: 'Kliendi konto number finantsmoodulis'
        + '{br}Vaikimisi väärtus tuleb üldseadistustest',
    shorthelp_companies_supplier_account_id: 'Tarnija konto number finantsmoodulis'
        + '{br}Vaikimisi väärtus tuleb üldseadistustest',

    shorthelp_companies_companycontacts_prefix: 'Kontakti prefiks{br}n Hr, Pr, Dr, Prof',
    shorthelp_companies_companycontacts_contact: 'Kontakti nimi{br}n Jaan Tamm',
    shorthelp_companies_companycontacts_phone: 'Kontakti telefoninumber{br}n +372 1234567',
    shorthelp_companies_companycontacts_email: 'Kontakti e-posti aadress',
    shorthelp_companies_companycontacts_memo: 'Kontaktiga seotud lühike lisaifo',
    shorthelp_companies_bank_accounts_bank_id: 'Panga valik',
    shorthelp_companies_bank_accounts_IBAN: 'Pangakonto, IBAN',
    shorthelp_companies_bank_accounts_memo: 'Lisaifo pangakonto kohta',

    shorthelp_articles_code: 'Kauba kood{br}Unikaalne kood, mida kasutatakse programmis sisemisel otstarbel nt kauba valikutes.',
    shorthelp_articles_name: 'Kauba nimetus{br}',
    shorthelp_articles_type_ext_id: 'Kauba tüüp{br}Kauba tüüp määrab, kuidas kaupa käsitletakse{br}nt kauba valikutes, laoarvestuses, müügiarvetel jm',
    shorthelp_articles_unit_id: 'Mõõtühik',
    shorthelp_articles_articlegroup_id: 'Kauba grupp{br}Kaubagrupi andmetest võivad tulla kauba vaikimisi konto ja maksuseadistused',
    shorthelp_articles_memo: 'Lisainfo sisemiseks kasutamiseks',
    shorthelp_articles_barcode: 'Kauba riba- või EAN kood',
    shorthelp_articles_salesprices_pricelist_id: 'Hinnakirja nimetus',
    shorthelp_articles_salesprices_price: 'Hind',
    shorthelp_articles_salesprices_discount: 'Allahindlusprotsent',
    shorthelp_articles_purchaseprices_company_id: 'Tarnija',
    shorthelp_articles_purchaseprices_supplierCode: 'Kauba kood tarnija infosüsteemis',
    shorthelp_articles_purchaseprices_purchasePrice: 'Ostuhind',
    shorthelp_articles_account_id: 'Müügikonto',
    shorthelp_articles_purchase_account_id: 'Ostukonto',
    shorthelp_articles_vat_id: 'Müügi km määr',
    shorthelp_articles_purchase_vat_id: 'Ostu km määr',
    shorthelp_units_code: 'Ühiku kood, unikaalne, kasutatakse valikutes',
    shorthelp_units_name: 'Ühiku nimetus',
    shorthelp_units_memo: 'Lisainfo sisemiseks kasutamiseks',
    shorthelp_currencies_code: 'Valuuta kood, unikaalne, kasutatakse valikutes',
    shorthelp_currencies_name: 'Valuuta nimetus',
    shorthelp_currencies_memo: 'Lisainfo sisemiseks kasutamiseks',
    shorthelp_currencies_rate: 'Valuuta kurss{br}Kui baasvaluuta on EUR, siis on see alati 1',
    shorthelp_currencies_drate: 'Valuuta kurss{br}Valuuta kurss, mis on määratud baasvaluuta suhtes',
    shorthelp_countries_code: 'Riigi kood, unikaalne, kasutatakse valikutes',
    shorthelp_countries_name: 'Riigi nimetus',
    shorthelp_countries_memo: 'Lisainfo sisemiseks kasutamiseks',
    shorthelp_countries_eu_code: 'Riigi standardne 2-täheline kood{br}Vt ISO 3166-1 Alpha-2 code:{br}https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes',
    shorthelp_accounts_code: 'Konto kood, unikaalne, kasutatakse valikutes',
    shorthelp_accounts_name: 'Konto nimetus',
    shorthelp_accounts_memo: 'Lisainfo sisemiseks kasutamiseks',
    shorthelp_accounts_accountGroupId: 'Konto rühm{br}Aktiva, passiva, tulu või kulu',
    shorthelp_accounts_type_id: 'Konto tüüp{br}Kandeid saab teha ainult detailsete kontodega.{br}Üldised kontod võtavad sama algusega alamkontod kokku.',
    swift: 'Swift',
    shorthelp_banks_code: 'Panga kood, unikaalne, kasutatakse valikutes',
    shorthelp_banks_name: 'Panga nimetus',
    shorthelp_banks_memo: 'Lisainfo sisemiseks kasutamiseks',
    shorthelp_banks_swift: 'Panga SWIFT kood',
    shorthelp_banks_eInvoiceChannel: 'Panga e-arve kanal',  // TODO
    shorthelp_banks_address: 'Panga aadress',
    shorthelp_salesinvoices_currency_id: 'Müügiarve valuuta valik',
    shorthelp_salesinvoices_drate: 'Müügiarve valuuta kurss',
    eInvoiceChannel: 'E-arve kanal',
    address: 'Aadress',

    shorthelp_warehouses_code: 'Ladu kood, unikaalne, kasutatakse valikutes',
    shorthelp_warehouses_name: 'Ladu nimetus',
    shorthelp_warehouses_memo: 'Lisainfo sisemiseks kasutamiseks',
    shorthelp_objects_code: 'Objekti kood, unikaalne, kasutatakse valikutes',
    shorthelp_objects_name: 'Objekti nimetus',
    shorthelp_objects_memo: 'Lisainfo sisemiseks kasutamiseks',
    shorthelp_vats_code: 'KM määra kood, unikaalne, kasutatakse valikutes',
    shorthelp_vats_name: 'KM määra nimetus',
    shorthelp_vats_memo: 'Lisainfo sisemiseks kasutamiseks',

    shorthelp_articlegroups_code: 'Kauba grupi kood, unikaalne, kasutatakse valikutes',
    shorthelp_articlegroups_name: 'Kauba grupi nimetus',
    shorthelp_articlegroups_memo: 'Lisainfo sisemiseks kasutamiseks',
    shorthelp_articlegroups_parent_id: 'Kauba grupi ülemgrupp',
    shorthelp_articlegroups_account_id: 'Müügikonto{br}Uue kauba lisamisel kasutatakse kauba müügikontona',
    shorthelp_articlegroups_vat_id: 'Müügi km määr{br}Kasutatakse uue kauba lisamisel',
    shorthelp_articlegroups_purchase_account_id: 'Ostukonto{br}Kasutatakse uue kauba lisamisel',
    shorthelp_articlegroups_purchase_vat_id: 'Ostu km määr{br}Kasutatakse uue kauba lisamisel',
    shorthelp_pricelists_code: 'Hinnakirja kood, unikaalne, kasutatakse valikutes',
    shorthelp_pricelists_name: 'Hinnakirja nimetus',
    shorthelp_pricelists_memo: 'Lisainfo sisemiseks kasutamiseks',
    shorthelp_companies_pricelist_id: 'Vaikimisi hinnakiri{br}Kasutatakse müügidokumentide koostamisel',

    tooltip_show_sidebar: 'Näita küljepaneeli',

    vat_report: 'Käibemaksu aruanne',
    shorthelp_vat_report_period_start: 'Perioodi alguskuupäev{br}Kohustuslik',
    shorthelp_vat_report_period_end: 'Perioodi lõppkuupäev{br}Kohustuslik',
    vat_name: 'Käibemaksu määr',
    vat_percent: 'KM %',
    msg_drop_files_here: 'Lohistage fail siia manuse lisamiseks',
    msg_save_document_to_attach: 'Salvestage dokumendi andmed manuste lisamiseks',

    unit_code: 'Ühik',
    group_code: 'Grupp',
    price_with_vat: 'Hind km-ga',
    sales_account: 'Müügikonto',
    purchases_account: 'Ostukonto',
    wh_qty: 'Laoseis',
    wh_total: 'Laoseis €',
    reserved_purchase: 'Ostu bron.',
    reserved_sales: 'Müügi bron.',
    available: 'Vaba jääk',
    wh_min_qty: 'Min.laoseis',
    purchase_need: 'Ostu vajadus',
    purchase_price: 'Ostuhind',
    purchase_currency_code: 'Valuuta',
    supplier_code: 'Tarnijakood',
    color: 'Värv',
    group_id: 'Grupp',
    supplier_id: 'Tarnija',
    shorthelp_articles_filter_group_id: 'Kuvatakse kaubad, mis kuuluvad valitud kaubagruppi',
    shorthelp_articles_filter_supplier_id: 'Kuvatakse kaubad, mille tarnija on valitud',
    shorthelp_articles_filter_active: 'Filter toimib vaid siis, kui see linnuke on sisse lülitatud',

    article_type_ext_0: 'Laoartikkel',
    article_type_ext_10: 'Toodang',
    article_type_ext_1000: 'Kuluartikkel',
    article_type_ext_2000: 'KÜ näit',
    article_type_ext_3000: 'KÜ püsitasu',
    company_type: 'Tüüp',
    legal_address: 'Jur.aadress',
    postal_address: 'Postiaadress',
    due_days: 'Maksetähtaeg',
    due_date_grid: 'Maksetähtaeg',
    contract: 'Leping',
    pricelist_name: 'Hinnakiri',

    shorthelp_articles_filter_article_type_ext_0: 'Kuvatakse laoartikleid',
    shorthelp_articles_filter_article_type_ext_10: 'Kuvatakse toodanguartikleid',
    shorthelp_articles_filter_article_type_ext_1000: 'Kuvatakse kuluartikleid',
    shorthelp_articles_filter_article_type_ext_2000: 'Kuvatakse KÜ näite',
    shorthelp_articles_filter_article_type_ext_3000: 'Kuvatakse KÜ püsitasusid',

    companygroups: 'Firmade grupid',
    shorthelp_companies_filter_group_id: 'Kuvatakse firmad, mis kuuluvad valitud gruppi',
    shorthelp_companies_filter_customers: 'Kuvatakse kliendid',
    customers: 'Kliendid',
    shorthelp_companies_filter_suppliers: 'Kuvatakse tarnijad',
    suppliers: 'Tarnijad',
    shorthelp_companies_filter_active_companies: 'Kuvatakse aktiivsed firmad',
    active_companies: 'Aktiivsed firmad',
    shorthelp_companies_filter_closed_companies: 'Kuvatakse suletud firmad',
    closed_companies: 'Suletud firmad',
    shorthelp_companies_filter_active: 'Filter toimib vaid siis, kui see linnuke on sisse lülitatud',

    // show_ui_shortHelp: 'Näidata lühikesi vihjeid kui hiir on elemendi kohal',
    'shorthelp_localSetup_ui.showHints': 'Näidata lühikesi vihjeid kui hiir on elemendi kohal',
    setup_user_title: 'Kasutaja seadistused',
    total_without_vat: 'Summa km-ta',
    vat_return_percent: 'Tagastus %',
    grid_salesinvoicerows: 'Kaubaread',
    purchase_payment_title: 'Tasumise info',
    paid_in_cash: 'Tasutud sularahas',
    payment_account_id: 'Tasumine kontolt',

    'due_period must be greater than or equal to 0': 'Maksetähtaeg ei saa olla väiksem kui null',
    expense_date: 'Kulu tekkimise kuupäev',
    title_registries: 'Registrid ja aruanded',
    title_favourites: 'Lemmikud',
    title_notes: 'Teated',
    shortcut_quick_add: 'Kiire lisamine',

    edit_companygroups_title: 'Firmade grupi {title} parandamine',
    new_companygroups_title: 'Uus firma grupp {title}',
    view_companygroups_title: 'Firmade grupp {title}',

    view_general_ledger_title: 'Kanne {title}',

    edit_companies_title: 'Firma "{title}" muutmine',
    new_companies_title: "Uus firma {title}",
    view_companies_title: "Firma {title}",

    edit_articles_title: 'Muuda kaupa {title}',
    new_articles_title: 'Uus kaup {title}',
    view_articles_title: 'Kaup {title}',

    edit_salesinvoices_title: 'Muuda müügiarvet {title}',
    new_salesinvoices_title: 'Uus müügiarve {title}',
    view_salesinvoices_title: 'Vaata müügiarvet {title}',
    new_salesinvoices_menu: 'Lisa müügiarve',
    
    edit_purchaseinvoices_title: 'Muuda ostuarvet {title}',
    new_purchaseinvoices_title: 'Uus ostuarve {title}',
    view_purchaseinvoices_title: 'Vaata ostuarvet {title}',
    new_purchaseinvoices_menu: 'Lisa ostuarve',

    edit_units_title: 'Mõõtühiku {title} parandamine',
    new_units_title: 'Uus mõõtühik {title}',

    new_currencies_title: 'Uus valuuta {title}',
    edit_currencies_title: 'Muuda valuuta {title}',

    edit_countries_title: 'Muuda riiki {title}',
    new_countries_title: 'Uus riik {title}',
    new_companies_menu: 'Lisa uus firma',
    new_articles_menu: 'Lisa uus artikkel',

    edit_accounts_title: 'Muuda kontot {title}',
    new_accounts_title: 'Uus konto {title}',

    new_banks_title: 'Uus pank {title}',
    edit_banks_title: 'Muuda pank {title}',

    edit_warehouses_title: 'Muuda ladu {title}',
    new_warehouses_title: 'Uus ladu {title}',

    edit_objects_title: 'Muuda objekti {title}',
    new_objects_title: 'Uus objekt {title}',

    edit_vats_title: 'Muuda käibemaksumäära {title}',
    new_vats_title: 'Uus käibemaksumäär {title}',

    new_articlegroups_title: 'Uus kaubagrupp {title}',
    edit_articlegroups_title: 'Muuda kaubagruppi {title}',

    edit_pricelists_title: 'Hinnakirja {title} parandamine',
    new_pricelists_title: 'Uus hinnakiri {title}',

    new_ledger_types_title: 'Uus kande tüüp {title}',
    edit_ledger_types_title: 'Muuda kande tüüpi {title}',

    new_general_ledger_title: 'Uus kande {title}',
    edit_general_ledger_title: 'Muuda kannet {title}',

    ledger_type_id: 'Kande tüüp',
    with_currency: 'Valuutakanne',
    document: 'Dokument',
    debit: 'Deebet',
    credit: 'Kreedit',
    total_d: 'D kokku',
    total_c: 'K kokku',
    grid_entries: 'Kande read',

    not_implemeted_msg: 'Funktsionaalsus ei ole veel valmis, kasutage vana kasutajaliidest',

    module_sales: 'Müük',
    module_purchases: 'Ost',
    module_common: 'Üldine',
    module_accounting: 'Finants',

    general_ledger: 'Pearaamat',
    ledger_types: 'Kannete tüübid',

    attachments: 'Manused',
    drop_attachments: 'Uus manus',
    
    vat_short: 'KM',
    objs: 'Objektid',
    obj_on_rows: 'Erinevad objektid ridadel',
    about: 'Programmist',
    help: 'Abi',
    // msg_archive_db: 'Kas oled kindel, et soovid andmebaasi kustutada?',
    gl_date: 'Kulu kuupäev',
    supplier_code_company: 'Tarnija kood',
    supplier_name: 'Tarnija nimi',
    summaryobj: 'Objektid',
    document_modified: 'Dokument muudetud',

    logo_alignment: 'Logo asend',
    logo_alignment_left: 'Vasakul',
    logo_alignment_center: 'Keskel',
    logo_alignment_right: 'Paremal',
    logo_width: 'Logo laius (%)',
    number_in_words: 'Summa sõnadega',
    ledger_type_code: 'KT',
    ledger_type_name: 'Kande tüüp',
    fiscal_period_code: 'Majandusaasta',
    doc: 'Dokument',
    unbound: 'Sidumata',
    status_id: 'Staatus',
    debit_base: 'D baas',
    credit_base: 'K baas',
    total_unbound: 'Sidumata kokku',

    module_capital_assets: 'Põhivara',
    ca_groups: 'Põhivara grupid',
    edit_ca_groups_title: 'Muuda põhivara gruppi {title}',
    new_ca_groups_title: 'Uus põhivara grupp {title}',
    edit_capital_assets_title: 'Muuda põhivara {title}',
    new_capital_assets_title: 'Uus põhivara {title}',
    capital_assets: 'Põhivara',
    purchase_date: 'Ostu kuupäev',
    amort_start_date: 'Amort kpv',
    purchase_value: 'Soetusmaksumus',
    residue_value: 'Jääkväärtus',
    calculation_method: 'Arvestusmeetod',
    ca_method_linear: 'Lineaarne',
    ca_method_withoud_amortisation: 'Ilma amortisatsioonita',
    amort_percent: 'Amort %',
    asset_account_id: 'Põhivara konto',
    amort_account_id: 'Amortisatsiooni konto',
    wear_account_id: 'Kulumikonto',
    grid_ca_modifications: 'Muudatused',
    amort_date: 'Amort kpv',
    modification: 'Muudatus',
    percent: 'Protsent',
    writeoff_block_title: 'Mahakandmine',
    writeoff_date: 'Mahakandmise kuupäev',
    writeoff_amort_date: 'Amort kuupäev',
    writeoff_memo: 'Mahakandmise lisainfo',
    ca_amortisation_report: 'Amortisatsiooni aruanne',
    initial_value: 'Algväärtus',
    value_modification: 'Ümberhindlus',
    amortisation: 'Amortisatsioon',
    capital_asset_id: 'Põhivara',
    group_name: 'Grupp',
    purchase_date_from: 'Ostu kuupäev alates',
    purchase_date_until: 'Ostu kuupäev kuni',
    writeoff_date_from: 'Mahakandmise kuupäev alates',
    writeoff_date_until: 'Mahakandmise kuupäev kuni',
    e_invoice_setup_title: 'E-arved',
    e_invoice_operator: 'Operaator',
    e_invoice_auth_key: 'Autentimisvõti',
    e_invoice_environment: 'Keskkond',
    e_invoice_attach_pdf: 'Oma PDF arvega kaasa',

    e_invoice_operator_finbite: 'Finbite',
    e_invoice_operator_rik: 'E-arveldaja / RIK',
    not_used: 'Ei kasuta',
    e_invoice_environment_test: 'Testkeskkond',
    e_invoice_environment_live: 'Töökeskkond',
    e_invoice_setup_sales: 'Müügiarved',
    e_invoice_setup_purchases: 'Ostuarved',
    e_invoice_setup_rik: 'E-arveldaja / RIK',
    e_invoice_setup_finbite: 'Finbite',
    e_invoice_setup_sales_parties: 'Firmad e-arvel',
    e_invoice_buyer_party: 'BuyerParty',
    e_invoice_payer_party: 'PayerParty',
    e_invoice_recipient_party: 'RecipientParty',
    not_exported: '--Ei ekspordi--',
    e_invoice_party_buyer: 'Ostja',
    e_invoice_party_payer: 'Maksja',
    purchases_report: 'Ostude aruanne',


    msg_error_processing_link: 'Viga lingi töötlemisel',
    error: 'Viga',

    e_invoice_setup_general: 'Üldine',

    percent_symbol: '%',
    ca_amortisation_report_title: 'Amortisatsiooni aruanne {period_start} - {period_end}',
    purchases_report_title: 'Ostude aruanne {period_start} - {period_end}',

    module_warehouse: 'Ladu',
    wh_inits: 'Algseisude sisestamine',
    wh_code: 'Ladu',
    wh_name: 'Lao nimetus',
    dst_wh_id: 'Sihtladu',
    total_cost: 'Laohind kokku',

    new_wh_inits_title: 'Uus algseis {title}',
    view_wh_inits_title: 'Vaata algseisu {title}',
    edit_wh_inits_title: 'Muuda algseisu {title}',
    grid_waybillrows: 'Kauabad',
    cost: 'Laohind',
    wh_incomes: 'Lao sissetulekud',
    edit_wh_incomes_title: 'Lao sissetulek {title}',
    new_wh_incomes_title: 'Uus lao sissetulek {title}',
    view_wh_incomes_title: 'Vaata lao sissetulekut {title}',

    wh_outcomes: 'Lao väljaminekud',
    customer_name: 'Klient',
    total_price: 'Müügihind kokku',
    edit_wh_outcomes_title: 'Lao väljaminek {title}',
    new_wh_outcomes_title: 'Uus lao väljaminek {title}',
    view_wh_outcomes_title: 'Lao väljaminek {title}',
    src_wh_id: 'Lähteladu',
    customer_id: 'Klient',
    recipient_id: 'Saaja',

    total_cost_from: 'Laosumma alates',
    total_cost_until: 'Laosumma kuni',
    total_price_from: 'Müügihinna summa alates',
    total_price_until: 'Müügihinna summa kuni',

    wh_intra: 'Ladudevahelised liikumised',

    wh_src_name: 'Lähteladu',
    wh_dst_name: 'Sihtladu',
    wh_src_id: 'Lähteladu',
    wh_dst_id: 'Sihtladu',

    "Can't lock this type of document": 'Seda tüüpi dokumenti ei saa kinnitada',
    edit_wh_intra_title: 'Ladudevaheline liikumine {title}',
    new_wh_intra_title: 'Uus ladudevaheline liikumine {title}',
    view_wh_intra_title: 'Ladudevaheline liikumine {title}',
    wh_writeoffs: 'Mahakandmised',

    edit_wh_writeoffs_title: 'Lao mahakandmine {title}',
    new_wh_writeoffs_title: 'Uus lao mahakandmine {title}',
    view_wh_writeoffs_title: 'Lao mahakandmine {title}',

    default_print_form: 'Vaikimisi väljatrükivorm',
    report_form: 'Väljatrükivorm',

    invoicing_title: 'Arveldus',

    unit_integers_singular: 'Ühik (ainsus)',
    unit_decimals_singular: 'Sendi ühik (ainsus)',
    unit_integers_plural: 'Ühik (mitmus)',
    unit_decimals_plural: 'Sendi ühik (mitmus)',
    logo: 'Logo',
    and: 'ja',

    emails: 'Meilid',
    addr_to: 'Saaja',
    addr_cc: 'Koopia (CC)',
    addr_bcc: 'Pimekoopia (BCC)',
    subject: 'Teema',
    body: 'Sisu',
    sent_date: 'Saadetud',
    new_emails_title: 'Uus e-kiri {title}',
    edit_emails_title: 'E-kiri {title}',
    view_emails_title: 'E-kiri {title}',

    setup_user_ui_title: 'Kasutajaliides',
    setup_email_title: 'E-post',

    no_tls: 'TLS puudub',
    implicit_tls: 'TLS automaatne (implicit)',
    explicit_tls: 'TLS käsitsi (explicit)',
    require_tls: 'TLS nõutud (require)',
    change_password: 'Muuda salasõna',
    new_password: 'Uus salasõna',
    change_password_msg: 'Sisestage uus salasõna ja vajutage OK',
    email_not_sent: 'E-kiri ei ole saadetud',
    email_sent: 'E-kiri on saadetud',

    msg_loading_privileges: 'Laen õigusi',
    msg_no_read_privilege: 'Puudub ligipääsu õigus (lugemine)',
    language: 'Keel',
    lang_et: 'Eesti keeles',
    lang_en: 'In English',

    invoice_recipient: 'Arve saaja',
    invoice_reg_code: 'Registrikood',
    invoice_vat_code: 'KMKR',
    invoice_total: 'Arve summa',
    invoice_condition: 'Tingimus',
    invoice_payment_conditions_long: 'Arve kuulub tasumisele {due_period} päeva jooksul pärast arve väljastamist.',
    invoice_payment_interest_long: 'Hilinemisel lisandub viivistasu {interest} % päevas.',
    invoice_issuer_signature: 'Arve väljastas',
    invoice_recipient_signature: "Arve võttis vastu",
    signature: 'Allkiri',
    article_or_service_name: 'Kauba/teenuse nimetus',
    invoice_payment_conditions_no_vat: 'Käibemaksu ei rakendu.',
    error_attachment_unique_violation: 'Manus nimega {name} on juba lisatud',
    title_error: 'Viga',
    'Field nr should be set when adding new entry': 'Number peab olema määratud uue kirje lisamisel',
    'Date is required': 'Kuupäev on kohustuslik',
    title_additional_info: 'Lisainfo',
    cn_code: 'KN kood',
    name2: 'Nimetus teises keeles',
    grid_unit_conversions: 'Konverteerimise reeglid',
    converted_unit_id: 'Konverteeritud ühik',
    coef: 'Koefitsient',
    title_no_read_privilege: 'Puudub lugemise õigus',
    eefr_economic_content: 'Majanduslik sisu',
    eefr_data_presentment: 'Andmeesitlusviis',
    eefr_card_title: 'Aastaaruande andmed',
    grid_eefr_company_related_parties: 'Seotud osapooled',
    eefr_related_party: 'Seotud osapool',
    eefr_asset_group: 'Vara grupp',
    eefr_change_type: 'Muutuse liik',
    from: 'Alates',
    until: 'Kuni',
    'User not found': 'Kasutaja ei leitud',
    account_main_id: 'MS',
    account_main_desc: 'Maj.sisu',
    dp_id: 'AEV',
    ag_id: 'VG',
    ct_id: 'ML',
    rp_id: 'SOP',

    subaccount_aev_desc: 'aev desc',
    account_code: 'Konto kood',
    d_base: 'Deebet',
    c_base: 'Kreedit',
    eefr_report_title: 'Aastaaruande andmed {period_start} - {period_end}',
    has_eefr: 'Aastaaruande andmed',
    title_salesinvoices_due: 'Laekumata müügiarved',
    more: 'Veel',
    'error loading setup': 'Viga seadistuste laadimisel',
    create_company_database_title: 'Loo ettevõtte andmebaas',
    create_company_database_msg: 'Sisestage ettevõtte andmebaasi nimi ja tüübi ja vajutage OK',
    tip_enter_company_name: 'Sisestage ettevõtte nimi (minimaalne pikkus 3 tähemärki)',
    tip_company_name_too_short: 'Ettevõtte nimi on liiga lühike',
    to_frontpage: 'Esilehele',
    msg_user_name_not_unique: 'Selline kasutajanimi on juba ära võetud',
    msg_user_name_invalid: 'Kasutajanimi võib sisaldada ainult tähti, numbreid, punkte ja allkriipse',
    billing_report: 'Arveldusaruanne',
    billing_dt: 'Kuupäev',
    amount: 'Summa',
    company: 'Ettevõte',
    doc_type: 'Dokumendi tüüp',
    doc_nr: 'Dokumendi nr',

    'database created': 'Andmebaas loodud',
    'sales': 'Müük',

    'auth/invalid-email': 'Vigane e-posti aadress',
    'auth/missing-password': 'Salasõna on kohustuslik',
    'auth/user-not-found': 'Kasutajat ei leitud',
    'auth/wrong-password': 'Vale salasõna',
    page_not_found: 'Ei leitud',
    page_not_found_message: 'Otsitavat lehte {path} ei leitud',
    doc_privacy_title: 'Privaatsustingimused',
    doc_terms_of_service_title: 'Kasutustingimused',

    // 2024-04-26
    unknown_page: 'Tundmatu leht',
    msg_unknown_page: 'Lehte ei leitud: {regName}',
    doc_load_error: 'Dokumendi laadimise viga',
    doc_load_error_message: 'Dokumendi laadimise viga',
    doc_index_title: 'Sõbralik majandustarkvara',
    hint_language: 'Keele valik',
    lang_ru: 'По-русски',
    prices: 'Hinnad',
    package: 'Pakett',
    billing_package_sales: 'Müük',
    billing_package_sales_description: 'Müügiarved, klientide haldus, hinnakirjad, seos finantsmooduliga',
    doc_prices_title: 'Hinnad',
    eur: 'EUR',
    eur_symbol: '€',
    'auth/weak-password': 'Salasõna on liiga nõrk, peab olema vähemalt 6 tähemärki',
    'auth/email-already-in-use': 'Meiliaadress on juba kasutusel',
    'auth/invalid-login-credentials': 'Vale kasutajanimi või salasõna',
    msg_already_have_account: 'Kasutajakonto on juba olemas?',
    msg_register_info: 'Vajutades nuppu "Loo konto" nõustute meie ',
    agree_to_terms: 'Kasutustingimustega',
    agree_to_privacy: 'Privaatsustingimustega',
    login_header: 'Logi sisse',
    reset_password_link: 'Unustasid salasõna?',
    reset_password_email_sent: 'Saatsime meili juhistega, kuidas salasõna taastada',
    msg_no_account: 'Ei ole veel Profiti kasutaja?',
    billing_package_purchases: 'Ostud',
    billing_package_purchases_description: 'Ostuarved, tarnijate haldus, seos finantsmooduliga',
    'No bank account found for sender': 'Saatja pangakonto puudub',

    // 2024-05-03

    setup_bank_accounts_title: 'Pangakontod',
    setup_update_db_title: 'Andmebaasi uuendamine',
    own_bank_accounts: 'Pangaarved',
    paymenttypes: 'Tasumise viisid',
    edit_paymenttypes_title: 'Muuda tasumise viisi {title}',
    new_paymenttypes_title: 'Uus tasumise viis {title}',
    prefer_for_bank_outcome: 'Eelistatud tasumiste jaoks',
    print_on_bank_requisits: 'Trüki panga rekvisiitidel',
    cashorder: 'Kassa order',
    bank_account: 'Pangaarve nr',
    iban: 'IBAN',
    title_report_loading_error: 'Viga aruande laadimisel',
    msg_report_loading_error_long: 'Aruande laadimine ebaõnnestus. {error}',
    log_entry: 'Logi kanne',
    btn_copy_to_clipboard: 'Kopeeri lõikelauale',
    btn_copied_to_clipboard: 'Kopeeritud lõikelauale',
    priority: 'Prioriteet',
    setup_warehouse_title: 'Ladu',

    'LADU.AUTOSTL.MYYK': 'Automaatsed saatelehed müügiarvetest',
    'LADU.AUTOSTL.OST': 'Automaatsed saatelehed ostuarvetest',

    report_run_pdf: 'Eelvaade',
    report_run_pdf_tooltip: 'Aruande eelvaade PDF formaadis',
    report_run_table: 'Tabel',
    report_run_table_tooltip: 'Aruande eelvaade tabeli formaadis',
    report_run_json: 'JSON',
    report_run_json_tooltip: 'Aruande väljund JSON formaadis',

    eefr_report: 'MAA käivete aruanne (RIK)',
    eefr_data: 'MAA andmed (RIK)',
    eefr_section_EE0301010: 'Mikroettevõtja andmesektsioon saldod EE0301010',
    eefr_section_EE0301020: 'Standard andmesektsioon saldod EE0301020',
    eefr_section_EE0302010: 'Perioodi käibed EE0302010',
    eefr_data_sections: 'Andmesektsioonid',

    close_preview: 'Sulge eelvaade',
    attachments_inline_preview_setup: 'Manuste eelvaate seadistus',
    ui_att_inline: 'Lubada manuste eelvaade dokumendi kaardil',
    ui_hints_show: 'Näidata lühikesi vihjeid kui hiir on elemendi kohal',
    ui_att_inline_right: 'Manuste eelvaade paremal kui võimalik',
    ui_att_inline_in_doc: 'Manuste eelvaade dokumendi kaardil',
    msg_no_delete_privilege: 'Puudub kustutamise õigus',
    msg_no_create_privilege: 'Puudub loomise õigus',
    msg_no_save_privilege: 'Puudub salvestamise õigus',
    balance_report: 'Bilanss',
    date1: 'Kuupäev 1',
    date2: 'Kuupäev 2',
    date3: 'Kuupäev 3',
    date4: 'Kuupäev 4',
    report: 'Aruanne',

    // 2024-09-06

    profit_report: 'Kasumiaruanne',
    period1_start: 'Periood 1 algus',
    period1_end: 'Periood 1 lõpp',
    period2_start: 'Periood 2 algus',
    period2_end: 'Periood 2 lõpp',
    period3_start: 'Periood 3 algus',
    period3_end: 'Periood 3 lõpp',
    period4_start: 'Periood 4 algus',
    period4_end: 'Periood 4 lõpp',

    // 2024-09-09
    'report_id is required': 'Aruande valik on kohustuslik',
    accounts_report: 'Konto aruanne',
    with_correspondence: 'Korrespondeeruvusega',
    corr_account_id: 'Korrespondeeruv konto',
    entry_order: 'Sisestuse järjekord',
    order_by_date: 'Kuupäeva järgi',
    type: 'Tüüp',
    ledger_nr: 'Kande nr',
    corr_account: 'Korr.konto',
    saldo: 'Saldo',
    start_saldo: 'Algsaldo',
    end_saldo: 'Lõppsaldo',
    accounts_report_title: 'Konto aruanne {period_start} - {period_end}',
    wh_state_report: 'Laoseis',
    name_code: 'Nimetus, Kood',
    group_code_article_code: 'Grupp, Kood',
    group_name_code: 'Grupp, Nimetus, Kood',
    wh_location_code: 'Lao asukoht, Kood',
    wh_location_name_code: 'Lao asukoht, Nimetus, Kood',
    wh_state_report_title: 'Laoseis {dt}',
    dt: 'Kuupäev',
    group: 'Grupp',
    no_null: 'Mitte näidata nullidega ridu',
    no_closed: 'Mitte näidata null-laoseisuga suletud ridu',
    reserved: 'Broneeringud',

    wh_article_ledger: 'Kauba käibeandmik',
    wh_article_ledger_title: 'Kauba käibeandmik {period_start} - {period_end}',
    wh_article_ledger_section_initial: 'Algseis',
    wh_article_ledger_section_in: 'Sissetulekud',
    wh_article_ledger_section_out: 'Väljaminekud',
    wh_article_ledger_section_final: 'Lõppseis',
    qty: 'Kogus',
    source: 'Allikas',
    destination: 'Siht',
    sales_price: 'Müügihind',
    section: 'Sektsioon',
    other_side: 'Vastaspool',
    wh_income_report_det: 'Lao sissetulekute aruanne',
    doc_date_row_nr: 'Kuupäev',
    company_name_doc_nr_row_nr: 'Firma, Dok nr',
    wh_income_report_det_title: 'Lao sissetulekute aruanne {period_start} - {period_end}',
    wh_outcome_report_det: 'Lao väljaminekute aruanne',
    wh_outcome_report_det_title: 'Lao väljaminekute aruanne {period_start} - {period_end}',
    income_expense_report: 'Tulude ja kulude aruanne',
    income_expense_report_title: 'Tulude ja kulude aruanne',
    section_title_incomes: 'Tulud',
    section_title_expenses: 'Kulud',
    section_title_profit_loss: 'Kasum (-Kahjum)',
    expense: 'Kulu',
    income: 'Tulu',
}

export default messages