import React from "react";
import { ReportDescriptor, ReportOutputKind } from "../../../../app/types/ReportDescriptor";
import { paramTypes } from "./const";
import { ApplicationUnit } from "../../../regs";
import { ReportDataDescriptor, ReportParams, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";
import { DocGridColumn } from "../../../../app/types";

const Form = React.lazy(() => import('./WhArticleLedgerForm'));

class WhArticleLedgerRD extends ReportDescriptor {

    reportEndpoint: string = 'wh_article_ledger';
    name: ApplicationUnit = 'wh_article_ledger';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public getOutputKinds(): ReportOutputKind[] {
        return ['pdf', 'json', 'table'];
    }

    public processTabularData(data: any): any {
        const ret: any[] = [];
        let sn: string = '';
        data.forEach((section: any) => {
            sn = section.section_name;
            section.rows.forEach((row: any) => {
                ret.push({
                    ...row,
                    section: sn,
                    price: sn === 'in' ? undefined : row.price,
                    price_total: sn === 'in' ? undefined : row.price * row.qty,
                });
            });
        });
        return ret;
    }

    public getTabularReportColumns(params: ReportParams): DocGridColumn[] {
        return [
            { name: 'section', width: 120, label: 'section' },
            { name: 'doc_nr', width: 120, label: 'doc_nr' },
            { name: 'doc_date', width: 120, label: 'doc_date', type: 'date' },
            { name: 'other_side_name', width: 200, label: 'other_side' },
            { name: 'qty', type: 'decimal', width: 80, align: 'right', label: 'wh_qty' },
            { name: 'cost', type: 'decimal', width: 80, align: 'right', label: 'cost' },
            { name: 'total', type: 'decimal', width: 100, align: 'right', label: 'total' },
            { name: 'price', type: 'decimal', width: 80, align: 'right', label: 'price' },
            { name: 'price_total', type: 'decimal', width: 100, align: 'right', label: 'total' },
        ];
    }

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        const reportParams = {...this.getReportParams(params)};   //TODO: order selector?
        return await this.prepareReportData(reportParams, paramTypes);
    }

    public getReportFormModule = async (reportData : ReportDataDescriptor) => 
        await import('./WhArticleLedgerPrintForm');

}

export const wh_article_ledger = new WhArticleLedgerRD();