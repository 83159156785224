import React from "react";
import { ReportDescriptor } from "../../../../app/types/ReportDescriptor";
import { ApplicationUnit } from "../../../regs";
import { ParamTypesDescriptors, ReportDataDescriptor, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";

const Form = React.lazy(() => import('./IncomeExpenseReportForm'));

const paramTypes : ParamTypesDescriptors = {
    'period1_start': {type: 'date'},
    'period1_end': {type: 'date'},
    'period2_start': {type: 'date'},
    'period2_end': {type: 'date'},
    'period3_start': {type: 'date'},
    'period3_end': {type: 'date'},
    'period4_start': {type: 'date'},
    'period4_end': {type: 'date'},
    'currency_id': {type: 'select', selectorRd: 'currencies', title: 'currency'},
    'object_id': {type: 'select', selectorRd: 'objects', title: 'object'},
};

class IncomeExpenseReportRD extends ReportDescriptor {

    reportEndpoint: string = 'income_expense_report';
    name: ApplicationUnit = 'income_expense_report';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        return await this.prepareReportData(this.getReportParams(params), paramTypes);
    }

    public getReportFormModule = async (reportData : ReportDataDescriptor) => 
        await import('./IncomeExpenseReportPrintForm');
}

export const income_expense_report = new IncomeExpenseReportRD();