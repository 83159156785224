import { warehouses } from './registries/warehouses/warehouses'
import { wh_inits } from './registries/wh_inits/wh_inits'
import { wh_incomes } from './registries/wh_incomes/wh_incomes'
import { wh_outcomes } from './registries/wh_outcomes/wh_outcomes'
import { wh_intra } from './registries/wh_intra/wh_intra'
import { wh_writeoffs } from './registries/wh_writeoffs/wh_writeoffs'
import { wh_state_report } from './reports/wh_state_report/wh_state_report'
import { wh_article_ledger } from './reports/wh_article_ledger/wh_article_ledger'
import { wh_income_report_det } from './reports/wh_income_report_det/wh_income_report_det'
import { wh_outcome_report_det } from './reports/wh_outcome_report_det/wh_outcome_report_det'


export const warehouse_regs = { warehouses, wh_inits, wh_incomes, wh_outcomes, wh_intra, wh_writeoffs, 
    wh_state_report, wh_article_ledger, wh_income_report_det, wh_outcome_report_det };

export type WarehouseApplicationUnit = 'warehouses' 
    | 'wh_inits' | 'wh_incomes' | 'wh_outcomes' | 'wh_intra' | 'wh_writeoffs' 
    | 'wh_state_report' | 'wh_article_ledger' | 'wh_income_report_det' | 'wh_outcome_report_det';
