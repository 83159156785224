import React from "react";
import { ReportDescriptor, ReportOutputKind } from "../../../../app/types/ReportDescriptor";
import { paramTypes } from "./const";
import { ApplicationUnit } from "../../../regs";
import { ReportDataDescriptor, ReportParams, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";
import { DocGridColumn } from "../../../../app/types";

const Form = React.lazy(() => import('./WhIncomeReportDetForm'));

class WhIncomeReportDetRD extends ReportDescriptor {

    reportEndpoint: string = 'wh_income_report_det';
    name: ApplicationUnit = 'wh_income_report_det';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public getOutputKinds(): ReportOutputKind[] {
        return ['pdf', 'json', 'table'];
    }

    public getTabularReportColumns(params: ReportParams): DocGridColumn[] {
        return [
            { name: 'doc_date', width: 100, label: 'date', type: 'date' },
            { name: 'doc_nr', width: 100 },
            { name: 'company_name', width: 120, label: 'source' },
            { name: 'article_code', width: 80, label: 'code' },
            { name: 'article_name', width: 200, label: 'name' },
            { name: 'qty', type: 'decimal', width: 100, align: 'right', label: 'wh_qty' },
            { name: 'cost', type: 'decimal', width: 100, align: 'right', label: 'cost' },
            { name: 'total', type: 'decimal', width: 100, align: 'right', label: 'total' },
        ];
    }

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        const reportParams = {...this.getReportParams(params)};   //TODO: order selector?
        return await this.prepareReportData(reportParams, paramTypes);
    }

    public getReportFormModule = async (reportData : ReportDataDescriptor) => 
        await import('./WhIncomeReportDetPrintForm');

}

export const wh_income_report_det = new WhIncomeReportDetRD();